import React from "react";
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
    DrawerFooter,
    DrawerBody,
    Button,
    HStack,
    Text,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useDisclosure,
} from "@chakra-ui/react"
import { PersonaPanelHeading } from "./PersonaPanelHeading"
import { GameManager } from "../../GameManager";
import { CommonTreasureIconButton, EpicTreasureIconButton, LegendaryTreasureIconButton, RareTreasureIconButton, TreasureBagIconButton } from "../TreasureIcons";
import { DamageModal } from "./DamageModal";

export const EnemyPanel = ({ ...props }) => {

    const [addHealth, setAddHealth] = React.useState(1);
    const [damage, setDamage] = React.useState(1);
    const gameManagerData = GameManager.Instance.GetData();
    const [treasureBagEmpty, setTreasureBagEmpty] = React.useState(false);
    const [remainingTreasure, setRemainingTreasure] = React.useState(gameManagerData.remainingTreasure);

    const [modalTitle, setModalTitle] = React.useState("");
    const [modalText, setModalText] = React.useState("");
    const emptyLootData = {Common: 0, Rare: 0, Epic: 0, Legendary: 0};
    const [modalLootData, setModalLootData] = React.useState(emptyLootData);
    const modalDisclosure = useDisclosure();

    const damageCallback = () => {
        const modalData = GameManager.Instance.GetSelectedPersona().Damage(damage);
        setDamage(1);
        if (modalData != null) {
            setModalTitle(modalData.title);
            setModalText(modalData.text);
            setModalLootData(modalData.loot ?? emptyLootData);
            modalDisclosure.onOpen();
        }
    };

    GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
        setTreasureBagEmpty(gameManagerData.treasureBag.Common === 0 && gameManagerData.treasureBag.Rare === 0 && gameManagerData.treasureBag.Epic === 0 && gameManagerData.treasureBag.Legendary === 0);
        setRemainingTreasure(gameManagerData.remainingTreasure);
    });

    return (
        <>
            <Drawer
                isOpen={props.isOpen}
                placement='right'
                onClose={props.onClose}
            >
                <DrawerOverlay>
                </DrawerOverlay>
                <DrawerContent>
                    <DrawerCloseButton />
                    <PersonaPanelHeading personaName={props.personadata.Name} personaDescription={props.personadata.Description} />

                    <DrawerBody alignItems="stretch">
                        <VStack spacing="2" alignItems="stretch" flex="1" h="100%">
                            <Button isDisabled={props.personadata.Level >= GameManager.Instance.MaxLevel} onClick={() => props.persona.LevelUp()}>
                                Level Up
                            </Button>
                            <HStack>
                                <NumberInput defaultValue={addHealth} value={addHealth} min={0} max={200} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => { setAddHealth(valueAsNumber) }} isDisabled={props.personadata.Health === props.persona.MaxHealth}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button isDisabled={props.personadata.Health === props.persona.MaxHealth} width="40" onClick={() => { GameManager.Instance.GetSelectedPersona().AddHealth(addHealth); setAddHealth(1); }}>
                                    Apply Health
                                </Button>
                            </HStack>
                            <HStack>
                                <NumberInput defaultValue={damage} value={damage} min={0} max={200} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => { setDamage(valueAsNumber) }} isDisabled={props.personadata.Health === 0}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button width="40" onClick={damageCallback} isDisabled={props.personadata.Health === 0}>
                                    Damage
                                </Button>
                            </HStack>
                            {props.personadata.NeedsDefaultLoot && <Button onClick={() => { props.persona.AddDefaultLoot(); }} colorScheme="green">Attach Default Loot</Button>}
                            <HStack>
                                <Text>Add Loot</Text>
                                <Spacer />
                                <TreasureBagIconButton isDisabled={treasureBagEmpty} onClick={() => { GameManager.Instance.AttachRandomLootToSelected(1) }} />
                                <CommonTreasureIconButton isDisabled={remainingTreasure.Common === 0} onClick={() => { GameManager.Instance.AttachLootToSelected(1, 0, 0, 0) }} />
                                <RareTreasureIconButton isDisabled={remainingTreasure.Rare === 0} onClick={() => { GameManager.Instance.AttachLootToSelected(0, 1, 0, 0) }} />
                                <EpicTreasureIconButton isDisabled={remainingTreasure.Epic === 0} onClick={() => { GameManager.Instance.AttachLootToSelected(0, 0, 1, 0) }} />
                                <LegendaryTreasureIconButton isDisabled={remainingTreasure.Legendary === 0} onClick={() => { GameManager.Instance.AttachLootToSelected(0, 0, 0, 1) }} hidden={true} />
                            </HStack>
                            <Spacer />
                            <Button onClick={() => { GameManager.Instance.DeleteEnemy(props.persona); props.onClose(); }} colorScheme="red">
                                Delete
                            </Button>
                        </VStack>
                    </DrawerBody>
                    <DrawerFooter>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <DamageModal isopen={modalDisclosure.isOpen} onclose={modalDisclosure.onClose} title={modalTitle} text={modalText} lootdata={modalLootData} />
        </>
    )
}
