import * as React from "react"
import {
  ChakraProvider,
  Divider,
  Flex,
  theme,
} from "@chakra-ui/react"
import { AppHeader } from "./components/AppHeader"
import { AppMainPanel } from "./components/AppMainPanel"
import { GameManager } from "./GameManager";

export const App = () => {
  new GameManager();

  React.useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" height="100vh" alignItems="stretch">
        <AppHeader height="40px"/>
        <Divider orientation="horizontal"/>
        <AppMainPanel height="calc(100% - 40px)"/>
      </Flex>
    </ChakraProvider>
  )
}
