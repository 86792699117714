import * as React from "react"
import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  VStack,
  DrawerFooter,
  DrawerBody,
  HStack,
  Switch,
  useBoolean,
  useColorMode,
} from "@chakra-ui/react"
import { EnemySettings } from "./Settings/EnemySettings";
import { TreasureSettings } from "./Settings/TreasureSettings";
import { PlayerSettings } from "./Settings/PlayerSettings";

export const AppSettings = ({ ...props }) => {
  const isOpen = props.isOpen;
  const onClose = props.onClose;
  const [flag, setFlag] = useBoolean(true);
  const { toggleColorMode } = useColorMode();


  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Settings</DrawerHeader>

          <DrawerBody>
            <VStack spacing="2" alignItems="stretch" flex="1">
              <HStack w="100%">
                <Text>Dark Mode</Text>
                <Switch isChecked={flag} onChange={e => { setFlag.toggle(); toggleColorMode(); }} />
              </HStack>
              <PlayerSettings />
              <EnemySettings />
              <TreasureSettings />

            </VStack>


          </DrawerBody>

          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
