import * as React from "react"
import {
    Checkbox,
} from "@chakra-ui/react"
import { IGameCard } from "../IGameCard";
import { GameManager } from "../GameManager";


export const PersonaItem = ({ ...props }) => {
    const card = props.card as IGameCard;
    const [visible, setVisible] = React.useState<boolean>(card.avaliable ?? false);
    GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
        setVisible(props.card.available ?? false);
    });

    return (
        <>
        {visible && <Checkbox key={card.name} {...props.getCheckboxProps({ value: card.name })}>{card.name}</Checkbox>}
        </>
    )
}
