import * as React from "react"
import {
    Text,
    Flex,
    Image,
    Tooltip,
} from "@chakra-ui/react"

export const GameCardHealth = ({ ...props }) => {
    return (
        <Tooltip label="Health remaining" placement="auto">
            <Flex direction="row">
                <Text paddingRight="2">{props.hp}</Text>
                <Image src="/images/Icons/health.png" boxSize="6" />
            </Flex>
        </Tooltip>
    )
}
