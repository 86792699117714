import { Enemy } from "./Enemy";
import { Player } from "./Player";
import GameData from "./GameData";
import { IGamePack } from "./IGamePack";
import { IPlayerCard } from "./IPlayerCard";
import { BasePersona } from "./BasePersona";
import { IGameManagerData } from "./IGameManagerData";
import { IEnemyCardBase } from "./IEnemyCardBase";
import { GameType } from "./GameType";
import { ILootData } from "./ILootData";

export class GameManager {
    public static Instance: GameManager;
    public firstLoad: boolean = true;
    //turn these into maps later
    public loadedPlayers: IPlayerCard[] = [];
    public loadedEnemies: IEnemyCardBase[] = [];
    public currentPlayers: Player[] = [];
    public currentEnemies: Enemy[] = [];
    public get MaxLevel(): number {
        return (this.gameType === GameType.OneShot) ? 5 : 10;
    }
    protected packs: IGamePack[] = [];
    protected selectedPersona: BasePersona = new BasePersona({ name: 'Unset', thumbnail: '' });
    protected uiUpdaters: Map<string, (gameManagerData: IGameManagerData) => void> = new Map();
    protected treasureBag: ILootData;
    protected remainingTreasure: ILootData;
    protected maxCommonLoot: number = 10;
    protected maxRareLoot: number = 22;
    protected maxEpicLoot: number = 22;
    protected maxLegendaryLoot: number = 4;
    protected gameType: GameType = GameType.OneShot;

    constructor() {
        this.treasureBag = {
            Common: 10,
            Rare: 5,
            Epic: 0,
            Legendary: 0,
        }
        this.remainingTreasure = {
            Common: this.maxCommonLoot - this.treasureBag.Common,
            Rare: this.maxRareLoot - this.treasureBag.Rare,
            Epic: this.maxEpicLoot - this.treasureBag.Epic,
            Legendary: this.maxLegendaryLoot - this.treasureBag.Legendary,
        }
        if (GameManager.Instance === undefined || GameManager.Instance === null) {
            GameManager.Instance = this;
        }
        else {
            return;
        }
        GameData.GetAllPacks().forEach(pack => {
            this.loadedPlayers.push(...pack.playerCards);
            this.loadedEnemies.push(...pack.mobCards);
            this.loadedEnemies.push(...pack.roamingMonsterCards);
            this.loadedEnemies.push(...pack.bossCards);
        });
    }

    public GetEnemy(name: string) {
        const found = this.currentEnemies.find(enemy => enemy.Name === name);
        if (found) {
            return found;
        }
        return null;
    }

    public GetOrCreateEnemy(name: string) {
        const found = this.GetEnemy(name);
        if (found) {
            return found;
        }
        else {
            const cardIndex = this.loadedEnemies.findIndex(enemy => enemy.name === name && enemy.avaliable === true);
            if (cardIndex >= 0) {
                const card = this.loadedEnemies[cardIndex];
                const enemy = new Enemy(card, this.currentPlayers.length, this.GetDungeonLevel());
                this.currentEnemies.push(enemy);
                card.avaliable = false;
                return enemy;
            }
        }
        return null;
    }

    public GetPlayer(name: string) {
        const found = this.currentPlayers.find(player => player.Name === name);
        if (found) {
            return found;
        }
        return null;
    }

    public GetOrCreatePlayer(name: string) {
        const found = this.GetPlayer(name);
        if (found) {
            return found;
        }
        else {
            const cardIndex = this.loadedPlayers.findIndex(player => player.name === name && player.avaliable === true);
            if (cardIndex >= 0) {
                const card = this.loadedPlayers[cardIndex];
                const player = new Player(card);
                this.currentPlayers.push(player);
                card.avaliable = false;
                return player;
            }
        }
        return null;
    }

    public AddRandomPlayer() {
        const randVal = Math.floor(Math.random() * this.loadedPlayers.length);
        const randCard = this.loadedPlayers[randVal];
        this.AddPlayer(randCard.name);
    }

    public AddPlayers(names: string[]) {
        names.forEach(name => {
            this.GetOrCreatePlayer(name);
        });
        this.UpdateUI();
    }

    public AddPlayer(name: string) {
        this.GetOrCreatePlayer(name);
        this.UpdateUI();
    }

    public AddEnemies(names: string[]) {
        names.forEach(name => {
            this.GetOrCreateEnemy(name);
        });
        this.UpdateUI();
    }

    public AddEnemy(name: string): void {
        this.GetOrCreateEnemy(name);
        this.UpdateUI();
    }

    public DeletePlayer(player: Player) {
        const index = this.currentPlayers.indexOf(player);
        if (index >= 0) {
            this.currentPlayers.splice(index, 1);
        }
        player.Delete();
        this.UpdateUI();
    }

    public DeleteEnemy(enemy: Enemy) {
        const loot = enemy.LootData;
        this.ReturnLootOnDeath(loot);

        const index = this.currentEnemies.indexOf(enemy);
        if (index >= 0) {
            this.currentEnemies.splice(index, 1);
        }
        enemy.Delete();
        this.UpdateUI();
    }

    public GetSelectedPersona(): BasePersona {
        return this.selectedPersona;
    }

    public SetSelectedPersona(persona: BasePersona) {
        this.selectedPersona = persona;
        this.UpdateUI();
    }

    public GetData(): IGameManagerData {
        return {
            selectedPersona: this.selectedPersona,
            dungeonLevel: this.GetDungeonLevel(),
            currentPlayersData: this.currentPlayers.map(p => p.GetData()),
            currentEnemiesData: this.currentEnemies.map(p => p.GetData()),
            treasureBag: this.GetLootData(this.treasureBag),
            remainingTreasure: this.GetLootData(this.remainingTreasure),
        };
    }

    protected GetLootData(loot: ILootData): ILootData {
        return {
            Common: loot.Common,
            Rare: loot.Rare,
            Epic: loot.Epic,
            Legendary: loot.Legendary,
            BorrowedCommon: loot.BorrowedCommon,
            BorrowedRare: loot.BorrowedRare,
            BorrowedEpic: loot.BorrowedEpic,
            BorrowedLegendary:loot.BorrowedLegendary,
        }
    }

    public UpdateUI() {
        this.uiUpdaters.forEach(callback => {
            callback(this.GetData());
        });
    }

    AttachUIUpdater(id: string, callback: (gameManagerData: IGameManagerData) => void) {
        this.uiUpdaters.set(id, callback);
    }

    public GetDungeonLevel() {
        let max = 1;
        for (let i = 0; i < this.currentPlayers.length; ++i) {
            max = Math.max(max, this.currentPlayers[i].Level);
        }
        return max;
    }

    public AddTreasureToBag(common: number, rare: number, epic: number, legendary: number) {
        this.treasureBag.Common += common;
        this.treasureBag.Rare += rare;
        this.treasureBag.Epic += epic;
        this.treasureBag.Legendary += legendary;
        this.remainingTreasure.Common -= common;
        this.remainingTreasure.Rare -= rare;
        this.remainingTreasure.Epic -= epic;
        this.remainingTreasure.Legendary -= legendary;
        this.UpdateUI();
    }

    public ReturnLootToBag(common: number, rare: number, epic: number, legendary: number) {
        this.treasureBag.Common += common;
        this.treasureBag.Rare += rare;
        this.treasureBag.Epic += epic;
        this.treasureBag.Legendary += legendary;
        this.treasureBag.BorrowedCommon = (this.treasureBag.BorrowedCommon??0) - common;
        this.treasureBag.BorrowedRare = (this.treasureBag.BorrowedRare??0) - rare;
        this.treasureBag.BorrowedEpic = (this.treasureBag.BorrowedEpic??0) - epic;
        this.treasureBag.BorrowedLegendary = (this.treasureBag.BorrowedLegendary??0) - legendary;
        this.UpdateUI();
    }

    public ReturnLootOnDeath(lootData: ILootData) {
        let treasureBagCommon = lootData.BorrowedCommon ?? 0;
        let treasureBagRare = lootData.BorrowedRare ?? 0;
        let treasureBagEpic = lootData.BorrowedEpic ?? 0;
        let treasureBagLegendary = lootData.BorrowedLegendary ?? 0;
        this.treasureBag.Common += treasureBagCommon;
        this.treasureBag.Rare += treasureBagRare;
        this.treasureBag.Epic += treasureBagEpic;
        this.treasureBag.Legendary += treasureBagLegendary;
        this.treasureBag.BorrowedCommon = (this.treasureBag.BorrowedCommon??0) - treasureBagCommon;
        this.treasureBag.BorrowedRare = (this.treasureBag.BorrowedRare??0) - treasureBagRare;
        this.treasureBag.BorrowedEpic = (this.treasureBag.BorrowedEpic??0) - treasureBagEpic;
        this.treasureBag.BorrowedLegendary = (this.treasureBag.BorrowedLegendary??0) - treasureBagLegendary;
        this.remainingTreasure.Common += lootData.Common - treasureBagCommon;
        this.remainingTreasure.Rare += lootData.Rare - treasureBagRare;
        this.remainingTreasure.Epic += lootData.Epic - treasureBagEpic;
        this.remainingTreasure.Legendary += lootData.Legendary - treasureBagLegendary;
    }

    protected RemoveTreasureFromBagWithData(lootData: ILootData) {
        this.treasureBag.Common -= lootData.Common;
        this.treasureBag.Rare -= lootData.Rare;
        this.treasureBag.Epic -= lootData.Epic;
        this.treasureBag.Legendary -= lootData.Legendary;
    }

    public GetLootFromBag(count: number): ILootData {
        const ret: ILootData = {
            Common: 0,
            Rare: 0,
            Epic: 0,
            Legendary: 0,
            BorrowedCommon: 0,
            BorrowedEpic: 0,
            BorrowedRare: 0,
            BorrowedLegendary: 0
        }
        const loot = Array<number>();
        if (this.treasureBag.Common > 0) {
            loot.push(...Array<number>(this.treasureBag.Common).fill(1));
        }
        if (this.treasureBag.Rare > 0) {
            loot.push(...Array<number>(this.treasureBag.Rare).fill(2));
        }
        if (this.treasureBag.Epic > 0) {
            loot.push(...Array<number>(this.treasureBag.Epic).fill(3));
        }
        if (this.treasureBag.Legendary > 0) {
            loot.push(...Array<number>(this.treasureBag.Legendary).fill(4));
        }
        for (let index = 0; index < count; index++) {
            if (loot.length === 0) {
                break;
            }
            const randIndex = Math.floor(Math.random() * loot.length);
            const lootType = loot[randIndex];
            loot.slice(randIndex, 1);
            switch (lootType) {
                case 1:
                    ret.Common += 1;
                    break;
                case 2:
                    ret.Rare += 1;
                    break;
                case 3:
                    ret.Epic += 1;
                    break;
                case 4:
                    ret.Legendary += 1;
                    break;
            }
        }
        ret.BorrowedCommon = ret.Common;
        ret.BorrowedRare = ret.Rare;
        ret.BorrowedEpic = ret.Epic;
        ret.BorrowedLegendary = ret.Legendary;
        this.RemoveTreasureFromBagWithData(ret);
        return ret;
    }

    public PeekLoot(count: number) {
        var loot = this.GetLootFromBag(count);
        this.ReturnLootToBag(loot.Common, loot.Rare, loot.Epic, loot.Legendary);
        return loot;
    }

    public BorrowLoot(count: number) {
        var loot = this.GetLootFromBag(count);
        this.treasureBag.BorrowedCommon = (this.treasureBag.BorrowedCommon??0) + (loot.BorrowedCommon??0);
        this.treasureBag.BorrowedRare = (this.treasureBag.BorrowedRare??0) + (loot.BorrowedRare??0);
        this.treasureBag.BorrowedEpic = (this.treasureBag.BorrowedEpic??0) + (loot.BorrowedEpic??0);
        this.treasureBag.BorrowedLegendary = (this.treasureBag.BorrowedLegendary??0) + (loot.BorrowedLegendary??0);
        this.UpdateUI();
        return loot;
    }

    public GetLootFromPile(common: number, rare: number, epic: number, legendary: number): ILootData {
        if (common > this.remainingTreasure.Common) {
            common = this.remainingTreasure.Common;
        }
        this.remainingTreasure.Common -= common;
        if (rare > this.remainingTreasure.Rare) {
            rare = this.remainingTreasure.Rare;
        }
        this.remainingTreasure.Rare -=rare;
        if (epic > this.remainingTreasure.Epic) {
            epic = this.remainingTreasure.Epic;
        }
        this.remainingTreasure.Epic -= epic;
        if (legendary > this.remainingTreasure.Legendary) {
            legendary = this.remainingTreasure.Legendary;
        }
        this.remainingTreasure.Legendary -= legendary;
        return {
            Common: common,
            Rare: rare,
            Epic: epic,
            Legendary: legendary,
        }
    }

    public AttachRandomLootToPersona(persona: BasePersona, count: number) {
        const loot = this.GetLootFromBag(count);
        persona.AddLoot(loot);
    }

    public AttachRandomLootToSelected(count: number) {
        this.AttachRandomLootToPersona(this.selectedPersona, count);
    }

    public AttachLootToPersona(persona: BasePersona, loot: ILootData) {
        if (loot.Common > this.remainingTreasure.Common) {
            loot.Common = this.remainingTreasure.Common;
        }
        this.remainingTreasure.Common -= loot.Common;
        if (loot.Rare > this.remainingTreasure.Rare) {
            loot.Rare = this.remainingTreasure.Rare;
        }
        this.remainingTreasure.Rare -= loot.Rare;
        if (loot.Epic > this.remainingTreasure.Epic) {
            loot.Epic = this.remainingTreasure.Epic;
        }
        this.remainingTreasure.Epic -= loot.Epic;
        if (loot.Legendary > this.remainingTreasure.Legendary) {
            loot.Legendary = this.remainingTreasure.Legendary;
        }
        this.remainingTreasure.Legendary -= loot.Legendary;

        persona.AddLoot(loot);
    }

    public AttachLootToSelected(common: number, rare: number, epic: number, legendary: number)
    {
        const loot: ILootData = {
            Common: common,
            Rare: rare,
            Epic: epic,
            Legendary: legendary,
            BorrowedCommon: 0,
            BorrowedEpic: 0,
            BorrowedRare: 0,
            BorrowedLegendary: 0
        }
        this.AttachLootToPersona(this.selectedPersona, loot);
    }
}
