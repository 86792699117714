import React from "react"
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useCheckboxGroup,
} from "@chakra-ui/react"
import { PersonaPicker } from "./PersonaPicker";

export const PersonaPickerModal = ({ ...props }) => {
  const { value, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
  });
  return (
    <>
      <Modal isOpen={props.isopen} onClose={props.onclose}>
        <ModalOverlay />
        <ModalContent maxH="80vh" overflowY="auto" justifySelf="center">
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PersonaPicker getcheckboxprops={getCheckboxProps} showplayers={props.showplayers} enemytype={props.enemytype} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => { props.onclose(); props.onpersonasselected(value as string[]); setValue([]); }}>Continue</Button>
            <Button variant='ghost' onClick={() => { props.onclose(); setValue([]); }}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
