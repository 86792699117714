import React from "react"
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    HStack,
    Spacer,
} from "@chakra-ui/react"
import { LootDrawnToken } from "../LootDrawnToken";
import { CommonTreasureIcon, RareTreasureIcon, EpicTreasureIcon, LegendaryTreasureIcon } from "../TreasureIcons";

export const LootDrawnModal = ({ ...props }) => {
    return (
        <>
            <Modal isOpen={props.isopen} onClose={props.onclose}>
                <ModalOverlay />
                <ModalContent maxH="80vh" overflowY="auto" justifySelf="center">
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack>
                            <Spacer />
                            {props.lootData.Common > 0 && <LootDrawnToken lootIcon={<CommonTreasureIcon h="10" />} count={props.lootData.Common} tooltipLabel="Common loot" />}
                            {props.lootData.Rare > 0 && <LootDrawnToken lootIcon={<RareTreasureIcon h="10" />} count={props.lootData.Rare} tooltipLabel="Rare loot" />}
                            {props.lootData.Epic > 0 && <LootDrawnToken lootIcon={<EpicTreasureIcon h="10" />} count={props.lootData.Epic} tooltipLabel="Epic loot" />}
                            {props.lootData.Legendary > 0 && <LootDrawnToken lootIcon={<LegendaryTreasureIcon h="10" />} count={props.lootData.Legendary} tooltipLabel="Legendary loot" />}
                            <Spacer />
                        </HStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => { props.onclose(); }}>Continue</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
