import * as React from "react"
import {
    Text,
    Flex,
    Tooltip,
} from "@chakra-ui/react"

export const LootDrawnToken = ({ ...props }) => {
    const fontSize = props.fontsize ?? "xl";
    const paddingLeft = props.paddingleft ?? "2";
    return (
        <Tooltip label={props.tooltipLabel} placement="auto">
            <Flex direction="row">
                {props.lootIcon}
                <Text paddingLeft={paddingLeft} fontSize={fontSize}>{"x" + props.count}</Text>
            </Flex>
        </Tooltip>
    )
}
