import * as React from "react"
import {
    Text,
    Flex,
    Spacer,
    Heading,
    Image,
    Button,
    Box,
} from "@chakra-ui/react"
import { GameCardMana } from "./GameCardMana"
import { GameCardHealth } from "./GameCardHealth"
import { useState } from "react"
import { BasePersona } from "../../BasePersona"
import { GameCardMinions } from "./GameCardMinions"
import { GameManager } from "../../GameManager"
import { GameCardLoot } from "./GameCardLoot"

export const GameCard = ({ ...props }) => {
    const persona = props.persona as BasePersona;
    const [personaData, setPersonaData] = useState(persona.GetData());
    const [cardColorScheme, setCardColorScheme] = useState<{}>({});
    persona.attachUI(React.useId(), (data) => {
        setPersonaData(data);
        if (data.Health <= 0) {
            setCardColorScheme({colorScheme:"red"});
        }
    });

    return (
        <>
            <Button {...cardColorScheme} aspectRatio="5/7" h="100%" flexShrink="0" flexDirection="column" alignItems="stretch" padding="0"
                onClick={() => { GameManager.Instance.SetSelectedPersona(props.persona); props.onPanelOpen(); }}>
                <Flex direction="column" alignItems="stretch" flex="1">
                    <Flex direction="column" paddingTop="2">
                        <Heading size='md' textAlign="center">{personaData.Name}</Heading>
                        <Flex direction="row">
                            <Spacer />
                            <Text textAlign="center" fontSize="xs">{personaData.Description}</Text>
                            <Spacer />
                        </Flex>
                    </Flex>
                    <Spacer />
                    <Box maxW="90%" aspectRatio="1" flexGrow="0" flexShrink="0" alignSelf="center" position="relative">
                        <Image src={personaData.Thumbnail} w="100%" h="100%" flex="0" borderRadius="12" />
                        <GameCardLoot personadata={personaData} position="absolute" bottom="0" left="0"/>
                    </Box>
                    <Spacer />
                    <Flex direction="row" paddingBottom="2" paddingLeft="2" paddingRight="2">
                        {personaData.ShowMana && <GameCardMana mana={personaData.Mana} />}
                        {personaData.ShowMinionsLeft && <GameCardMinions minions={personaData.MinionsLeft} />}
                        <Spacer />
                        <GameCardHealth hp={personaData.Health} />
                    </Flex>
                </Flex>
            </Button>
        </>

    )
}
