import * as React from "react"
import {
  Button,
  Divider,
  AbsoluteCenter,
  Box,
  useDisclosure,
} from "@chakra-ui/react"
import { GameManager } from "../../GameManager"
import { PersonaPickerModal } from "../PersonaPickerModal"


export const PlayerSettings = ({ ...props }) => {
  const onPersonasSelected = (personas: string[]) => {
    GameManager.Instance.AddPlayers(personas);
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box position='relative' padding='4'>
        <Divider />
        <AbsoluteCenter px='4'>
          Players
        </AbsoluteCenter>
      </Box>
      <Button onClick={onOpen}>
        Add Players
      </Button>
      <PersonaPickerModal isopen={isOpen} onclose={onClose} showplayers={true} title="Select Players" onpersonasselected={onPersonasSelected} />
    </>
  )
}
