import { GameManager } from "./GameManager";
import { IDamageModalData } from "./IDamageModalData";
import { IGameCard } from "./IGameCard";
import { ILootData } from "./ILootData";
import { IPersonaData } from "./IPersonaData";

export class BasePersona {
    public Name: string;
    public Thumbnail: string;
    public get Description(): string {
        return "Level " + this.Level;
    }
    public get Level(): number {
        return this._level;
    }
    public get MaxHealth(): number {
        return 1;
    }
    public get Health(): number {
        return this._health;
    }
    protected set Health(value: number) {
        this._health = value;
    }
    private _health: number = 0;
    public get Mana(): number {
        return 0;
    }
    public get MinionsLeft(): number {
        return 0;
    }
    public get ShowMana(): boolean {
        return false;
    }
    public get ShowMinionsLeft(): boolean {
        return false;
    }
    public get Hidden(): boolean {
        return this._hidden;
    }
    public set Hidden(value: boolean) {
        this._hidden = value;
        this.UpdateUI();
    }
    private _hidden: boolean = true;
    public get NeedsDefaultLoot(): boolean {
        return this._needsDefaultLoot;
    }
    public set NeedsDefaultLoot(value: boolean) {
        this._needsDefaultLoot = value;
        this.UpdateUI();
    }
    private _needsDefaultLoot: boolean = true;
    public get HasLoot(): boolean {
        return this.LootData.Common + this.LootData.Rare + this.LootData.Epic + this.LootData.Legendary > 0;
    }
    public LootData: ILootData = {
        Common: 0,
        Rare: 0,
        Epic: 0,
        Legendary: 0,
        BorrowedCommon: 0,
        BorrowedRare: 0,
        BorrowedEpic: 0,
        BorrowedLegendary: 0
    };

    //public IList<LootType> AttachedLoot { get; protected set; }

    protected set Level(value: number) {
        this._level = value;
    }


    private _card: IGameCard;
    private _level: number = 1;
    protected uiUpdaters: Map<string, (personaData: IPersonaData) => void> = new Map();


    public constructor(card: IGameCard) {
        this._card = card;
        this.Name = card.name;
        this.Thumbnail = card.thumbnail;
        this.Hidden = false;
    }

    public Delete() {
        this._card.avaliable = true;
    }

    public attachUI(id: string, uiCallback: (personaData: IPersonaData) => void) {
        this.uiUpdaters.set(id, uiCallback);
    }

    public AddHealth(amount: number)
    {
        if (amount < 0)
        {
            this.Damage(amount);
            return;
        }
        this.Health += amount;
        this.CapHealth();
        this.UpdateUI();
    }

    public Damage(amount: number): IDamageModalData | null {
        return null;
    }

    public AddMana(amount: number) {

    }

    public UseMana(amount: number) {

    }

    public LevelUp() {

    }

    protected CapHealth()
    {
        if (this.Health > this.MaxHealth)
        {
            this.Health = this.MaxHealth;
        }
    }

    protected UpdateUI()
    {
        this.uiUpdaters.forEach(callback => {
            callback(this.GetData());
        });
        if (GameManager.Instance != null) {
            GameManager.Instance.UpdateUI();
        }
    }

    protected AddToBorrowedValue(a: number | undefined, b: number  | undefined): number {
        let ret: number = 0;
        ret += a ?? 0
        if (a)
        {
            ;
        }
        if (b)
        {
            ret += b;
        }
        return ret;
    }

    public AddLoot(lootData: ILootData) {
        this.LootData.Common += lootData.Common;
        this.LootData.Rare += lootData.Rare;
        this.LootData.Epic += lootData.Epic;
        this.LootData.Legendary += lootData.Legendary;
        this.LootData.BorrowedCommon = (this.LootData.BorrowedCommon ?? 0) + (lootData.BorrowedCommon ?? 0);
        this.LootData.BorrowedRare = (this.LootData.BorrowedRare ?? 0) + (lootData.BorrowedRare ?? 0);
        this.LootData.BorrowedEpic = (this.LootData.BorrowedEpic ?? 0) + (lootData.BorrowedEpic ?? 0);
        this.LootData.BorrowedLegendary = (this.LootData.BorrowedLegendary ?? 0) + (lootData.BorrowedLegendary ?? 0);
        this.UpdateUI();
    }

    public AddDefaultLoot() {

    }

    public GetData(): IPersonaData {
        return {
            Name: this.Name,
            Thumbnail: this.Thumbnail,
            Description: this.Description,
            Level: this.Level,
            MaxHealth: this.MaxHealth,
            Health: this.Health,
            Mana: this.Mana,
            MinionsLeft: this.MinionsLeft,
            ShowMana: this.ShowMana,
            ShowMinionsLeft: this.ShowMinionsLeft,
            Hidden: this.Hidden,
            HasLoot: this.HasLoot,
            LootData: this.LootData,
            NeedsDefaultLoot: this.NeedsDefaultLoot,
        }
    }
}
