import * as React from "react"
import {
    Text,
    Flex,
    Tooltip,
} from "@chakra-ui/react"

export const GameCardLootToken = ({ ...props }) => {
    return (
        <Tooltip label={props.tooltipLabel} placement="auto">
            <Flex direction="row">
                {props.lootIcon}
                <Text>{"x" + props.count}</Text>
            </Flex>
        </Tooltip>
    )
}
