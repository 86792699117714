import * as React from "react"
import {
  Divider,
  Flex, useDisclosure,
} from "@chakra-ui/react"
import { PlayerCardsPanel } from "./CardPanels/PlayerCardsPanel"
import { EnemyCardsPanel } from "./CardPanels/EnemyCardsPanel"

import { GameManager } from "../GameManager"
import { EnemyPanel } from "./PersonaPanels/EnemyPanel"
import { PlayerPanel } from "./PersonaPanels/PlayerPanel"
import { BasePersona } from "../BasePersona"

export const AppMainPanel = ({...props}) => {
  const [persona, setPersona] = React.useState<BasePersona>(GameManager.Instance.GetSelectedPersona());
  const [personaData, setPersonaData] = React.useState(persona.GetData());

  GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
    const persona = gameManagerData.selectedPersona;
    setPersona(persona);
    setPersonaData(persona.GetData());
  });

  const playerDisclosure = useDisclosure();
  const enemyDisclosure = useDisclosure();

  return (
    <Flex direction="column" alignItems="stretch" h={props.height}>
      <PlayerCardsPanel bgColor="whiteAlpha.100" onPanelOpen={playerDisclosure.onOpen}/>
      <Divider orientation="horizontal"/>
      <EnemyCardsPanel bgColor="blackAlpha.100" onPanelOpen={enemyDisclosure.onOpen}/>
      <PlayerPanel isOpen={playerDisclosure.isOpen} onClose={playerDisclosure.onClose} persona={persona} personadata={personaData}/>
      <EnemyPanel isOpen={enemyDisclosure.isOpen} onClose={enemyDisclosure.onClose} persona={persona} personadata={personaData}/>
    </Flex>
  )
}
