import { BasePersona } from "./BasePersona";
import { GameManager } from "./GameManager";
import { ILootData } from "./ILootData";
import { IPlayerCard } from "./IPlayerCard";

export class Player extends BasePersona {
    public override get Description(): string {
        return "Level " + this.Level + " " + this._playerCard.classType;
    }
    public override get MaxHealth(): number {
        return this._playerCard.baseHealth + Math.floor(this.Level / 2);
    }
    public get MaxMana(): number {
        return this._playerCard.baseMana + Math.floor((this.Level - 1) / 2);
    }
    public override get ShowMana(): boolean {
        return true;
    }
    public override get Mana(): number {
        return this._mana;
    }
    protected set Mana(value: number) {
        this._mana = value;
    }
    private _mana: number = 0;
    public override get HasLoot(): boolean {
        return false;
    }

    private _playerCard: IPlayerCard;

    public constructor(card: IPlayerCard) {
        super(card);
        this._playerCard = card;
        this.Level = 1;
        this.Health = this.MaxHealth;
        this.Mana = this.MaxMana;
        GameManager.Instance.GetLootFromPile(0,1,3,0);
    }

    public override Damage(damage: number) {
        this.Health -= damage;
        if (this.Health <= 0) {
            this.Health = 0;
            //OnKO();
        }
        this.UpdateUI();
        if (this.Health <= 0) {
            return {
                title: "Player Died",
                text: "You can use a Lightbringer token to revive the player",
                loot: null,
            }
        }
        return null;
    }

    public override AddMana(amount: number) {
        this.Mana += amount;
        if (this.Mana > this.MaxMana) {
            this.Mana = this.MaxMana;
        }
        this.UpdateUI();
    }

    public override UseMana(amount: number) {
        this.Mana -= amount;
        if (this.Mana <= 0) {
            this.Mana = 0;
        }
        this.UpdateUI();
    }

    public override LevelUp() {
        if (this.Level === GameManager.Instance.MaxLevel) {
            return;
        }
        const oldMaxHealth = this.MaxHealth;
        const oldMaxMana = this.MaxMana;
        ++this.Level;
        const newMaxHealth = this.MaxHealth;
        const newMaxMana = this.MaxMana;
        this.Health += (newMaxHealth - oldMaxHealth);
        this.Mana += (newMaxMana - oldMaxMana);
        const newLoot = this.GetLootAtLevel();
        GameManager.Instance.ReturnLootToBag(newLoot.Common, newLoot.Rare, newLoot.Epic, newLoot.Legendary);
        this.UpdateUI();
    }

    protected GetLootData(common: number, rare: number, epic: number, legendary: number) {
        return {
            Common: common,
            Rare: rare,
            Epic: epic,
            Legendary: legendary,
        }
    }

    protected GetLootAtLevel(): ILootData {
        switch (this.Level) {
            default:
                return this.GetLootData(0, 0, 0, 0);
            case 2:
                return this.GetLootData(0, 1, 0, 0);
            case 3:
                return this.GetLootData(0, 0, 1, 0);
            case 4:
                return this.GetLootData(0, 0, 1, 0);
            case 5:
                return this.GetLootData(0, 0, 1, 0);
        }
    }
}
