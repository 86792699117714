import * as React from "react"
import {
    Button,
    Divider,
    AbsoluteCenter,
    Box,
    useDisclosure,
} from "@chakra-ui/react"
import { PersonaPickerModal } from "../PersonaPickerModal";
import { GameManager } from "../../GameManager";
import { EnemyType } from "../../EnemyType";


export const EnemySettings = ({ ...props }) => {
    const mobDisclosure = useDisclosure();
    const roamingDisclosure = useDisclosure();
    const bossDisclosure = useDisclosure();
    const onPersonasSelected = (personas: string[]) => {
        GameManager.Instance.AddEnemies(personas);
    }
    return (
        <>
            <Box position='relative' padding='4'>
                <Divider />
                <AbsoluteCenter px='4'>
                    Enemies
                </AbsoluteCenter>
            </Box>
            <Button onClick={mobDisclosure.onOpen}>
                Add Mobs
            </Button>
            <Button onClick={roamingDisclosure.onOpen}>
                Add Roaming Monsters
            </Button>
            <Button onClick={bossDisclosure.onOpen}>
                Add Bosses
            </Button>
            <PersonaPickerModal isopen={mobDisclosure.isOpen} onclose={mobDisclosure.onClose} showplayers={false} enemytype={EnemyType.Mob} title="Select Mobs" onpersonasselected={onPersonasSelected} />
            <PersonaPickerModal isopen={roamingDisclosure.isOpen} onclose={roamingDisclosure.onClose} showplayers={false} enemytype={EnemyType.RoamingMonster} title="Select Roaming Monsters" onpersonasselected={onPersonasSelected} />
            <PersonaPickerModal isopen={bossDisclosure.isOpen} onclose={bossDisclosure.onClose} showplayers={false} enemytype={EnemyType.Boss} title="Select Bosses" onpersonasselected={onPersonasSelected} />

        </>
    )
}
