import * as React from "react"
import {
    IconButton,
    Image,
    Tooltip,
} from "@chakra-ui/react"

export const CommonTreasureIconButton = ({ ...props }) => {
    return (
        <Tooltip label="Common treasure" placement="auto">
            <IconButton {...props} aria-label="Common treasure" icon={<CommonTreasureIcon h="80%" />} />
        </Tooltip>
    )
}

export const RareTreasureIconButton = ({ ...props }) => {
    return (
        <Tooltip label="Rare treasure" placement="auto">
            <IconButton {...props} aria-label="Rare treasure" icon={<RareTreasureIcon h="80%" />} />
        </Tooltip>
    )
}

export const EpicTreasureIconButton = ({ ...props }) => {
    return (
        <Tooltip label="Epic treasure" placement="auto">
            <IconButton {...props} aria-label="Epic treasure" icon={<EpicTreasureIcon h="80%" />} />
        </Tooltip>
    )
}

export const LegendaryTreasureIconButton = ({ ...props }) => {
    return (
        <Tooltip label="Legendary treasure" placement="auto">
            <IconButton {...props} aria-label="Legendary treasure" icon={<LegendaryTreasureIcon h="80%" />} />
        </Tooltip>
    )
}

export const TreasureBagIconButton = ({ ...props }) => {
    return (
        <Tooltip label="From treasure bag" placement="auto">
            <IconButton {...props} aria-label="Treasure bag" icon={<TreasureBagIcon h="80%" />} />
        </Tooltip>
    )
}

export const CommonTreasureIcon = ({ ...props }) => {
    return (
        <TreasureIcon src="/images/Icons/Loot/common.png" {...props} />
    )
}

export const RareTreasureIcon = ({ ...props }) => {
    return (
        <TreasureIcon src="/images/Icons/Loot/rare.png" {...props} />
    )
}

export const EpicTreasureIcon = ({ ...props }) => {
    return (
        <TreasureIcon src="/images/Icons/Loot/epic.png" {...props}/>
    )
}

export const LegendaryTreasureIcon = ({ ...props }) => {
    return (
        <TreasureIcon src="/images/Icons/Loot/legendary.png" {...props} />
    )
}

export const TreasureBagIcon = ({...props}) => {
    return (
        <TreasureIcon src="/images/Icons/Loot/treasure_bag_colored.png" {...props} />
    )
}

export const TreasureIcon = ({ ...props }) => {
    return (
        <Image {...props} aspectRatio="1" flexGrow="0" flexShrink="0" alignSelf="center" />
    )
}
