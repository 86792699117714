import React from "react"
import {
    CheckboxGroup,
    Stack,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
} from "@chakra-ui/react"
import { PersonaItem } from "./PersonaItem";
import { EnemyType } from "../EnemyType";
import { IGameCard } from "../IGameCard";
import { IGamePack } from "../IGamePack";
import { GameManager } from "../GameManager";


export const PersonaPackAccordion = ({ ...props }) => {
    const getCheckboxProps = props.getcheckboxprops;
    const showPlayers = props.showplayers as boolean;
    const enemyType = props.enemytype as EnemyType;
    const pack = props.pack as IGamePack;
    let cards: IGameCard[] = pack.playerCards;
    if (!showPlayers) {
        switch (enemyType) {
            case EnemyType.Mob:
                cards = pack.mobCards;
                break;
            case EnemyType.RoamingMonster:
                cards = pack.roamingMonsterCards;
                break;
            case EnemyType.Boss:
                cards = pack.bossCards;
                break;
        }
    }
    const getAvailable = () => {
        let anyAvailable = false;
        for (let index = 0; index < cards.length; index++) {
            if (cards[index].avaliable) {
                anyAvailable = true;
                break;
            }
        }
        return anyAvailable;
    }
    const [hasAvailable, setHasAvailable] = React.useState(getAvailable());
    getAvailable();

    GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
        setHasAvailable(getAvailable());
    });

    const personaCheckboxes: React.ReactNode[] = [];
    for (let index = 0; index < cards.length; index++) {
        const card = cards[index];
        personaCheckboxes.push(<PersonaItem card={card} key={card.name} getCheckboxProps={getCheckboxProps} />)
    }
    return (
        <>
            {hasAvailable && <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                            {pack.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <CheckboxGroup>
                        <Stack direction='column'>
                            { personaCheckboxes }
                        </Stack>
                    </CheckboxGroup>
                </AccordionPanel>
            </AccordionItem>}
        </>

    );
}
