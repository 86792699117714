import React from "react"
import {
  Flex,
  Spacer,
  useDisclosure,
  IconButton,
  Center,
  Tooltip,
  Heading,
} from "@chakra-ui/react"
import { AppSettings } from "./AppSettings";
import { FaUserCog } from "react-icons/fa"
import { GameManager } from "../GameManager";


export const AppHeader = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dungeonLevel, setDungeonLevel] = React.useState(GameManager.Instance.GetDungeonLevel());
  GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
    setDungeonLevel(GameManager.Instance.GetDungeonLevel());
  });

  return (
    <Flex height={props.height}>
      <Tooltip label="The max level of any player" placement="bottom-start">
        <Center paddingLeft="2">
          <Heading as="h2" size='md'>Dungeon Level: {dungeonLevel}</Heading>
        </Center>
      </Tooltip>
      <Spacer />
      <Tooltip label="Game actions and settings" placement="bottom-start">
        <IconButton
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          marginLeft="2"
          onClick={onOpen}
          icon={<FaUserCog />}
          aria-label={"Settings"}
        />
      </Tooltip>
      <AppSettings isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
