import * as React from "react"
import {
    Text,
    Flex,
    Image,
    Tooltip,
} from "@chakra-ui/react"

export const GameCardMinions = ({ ...props }) => {
    return (
        <Tooltip label="Minions left" placement="auto">
            <Flex direction="row" hidden={props.hidden}>
                <Image src="/images/Icons/minion.png" boxSize="6" />
                <Text paddingLeft="2">{props.minions}</Text>
            </Flex>
        </Tooltip>
    )
}
