import React from "react";
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
    DrawerFooter,
    DrawerBody,
    Button,
    HStack,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Spacer,
} from "@chakra-ui/react"
import { PersonaPanelHeading } from "./PersonaPanelHeading"
import { GameManager } from "../../GameManager";

export const PlayerPanel = ({ ...props }) => {

    const [addHealth, setAddHealth] = React.useState(1);
    const [damage, setDamage] = React.useState(1);
    const [addMana, setAddMana] = React.useState(1);
    const [useMana, setUseMana] = React.useState(1);

    return (
        <>
            <Drawer
                isOpen={props.isOpen}
                placement='right'
                onClose={props.onClose}
            >
                <DrawerOverlay>
                </DrawerOverlay>
                <DrawerContent>
                    <DrawerCloseButton />
                    <PersonaPanelHeading personaName={props.personadata.Name} personaDescription={props.personadata.Description} />

                    <DrawerBody alignItems="stretch">
                        <VStack spacing="2" alignItems="stretch" flex="1" h="100%">
                            <Button isDisabled={props.personadata.Level >= GameManager.Instance.MaxLevel} onClick={() => props.persona.LevelUp()}>
                                Level Up
                            </Button>
                            <HStack>
                                <NumberInput defaultValue={addHealth} value={addHealth} min={1} max={200} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => { setAddHealth(valueAsNumber) }} isDisabled={props.personadata.Health === props.persona.MaxHealth}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button isDisabled={props.personadata.Health === props.persona.MaxHealth} width="40" onClick={() => { GameManager.Instance.GetSelectedPersona().AddHealth(addHealth); setAddHealth(1); }}>
                                    Add Health
                                </Button>
                            </HStack>
                            <HStack>
                                <NumberInput defaultValue={damage} value={damage} min={1} max={200} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => { setDamage(valueAsNumber) }} isDisabled={props.personadata.Health === 0}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button width="40" isDisabled={props.personadata.Health === 0} onClick={() => { GameManager.Instance.GetSelectedPersona().Damage(damage); setDamage(1); }}>
                                    Damage
                                </Button>
                            </HStack>
                            <HStack>
                            <NumberInput defaultValue={addMana} value={addMana} min={1} max={200} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => {setAddMana(valueAsNumber)}} isDisabled={props.personadata.Mana === props.persona.MaxMana}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button isDisabled={props.personadata.Mana === props.persona.MaxMana} width="40" onClick={() => { GameManager.Instance.GetSelectedPersona().AddMana(addMana); setAddMana(1); }}>
                                    Add Mana
                                </Button>
                            </HStack>
                            <HStack>
                            <NumberInput defaultValue={useMana} value={useMana} min={1} max={10} keepWithinRange={true} onChange={(valueAsString, valueAsNumber) => {setUseMana(valueAsNumber)}} isDisabled={props.personadata.Mana <= 0}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <Button isDisabled={props.personadata.Mana <= 0} width="40" onClick={() => { GameManager.Instance.GetSelectedPersona().UseMana(useMana); setUseMana(1); }}>
                                    Use Mana
                                </Button>
                            </HStack>
                            <Spacer />
                            <Button onClick={() => { GameManager.Instance.DeletePlayer(props.persona); props.onClose(); }} colorScheme="red">
                                Delete
                            </Button>
                        </VStack>
                    </DrawerBody>

                    <DrawerFooter>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}
