import * as React from "react"
import {
    Card,
    CardBody,
} from "@chakra-ui/react"
import { GameCardLootToken } from "./GameCardLootToken"
import { CommonTreasureIcon, EpicTreasureIcon, LegendaryTreasureIcon, RareTreasureIcon } from "../TreasureIcons"

export const GameCardLoot = ({ ...props }) => {
    return (
        <>
            {(props.personadata.HasLoot) && <Card {...props} margin="0" borderRadius="12" borderTopStartRadius="0" borderBottomEndRadius="0">
                <CardBody margin="0" padding="1">
                    {props.personadata.LootData.Common > 0 && <GameCardLootToken lootIcon={<CommonTreasureIcon h="6" />} count={props.personadata.LootData.Common} tooltipLabel="Common loot" />}
                    {props.personadata.LootData.Rare > 0 && <GameCardLootToken lootIcon={<RareTreasureIcon h="6" />} count={props.personadata.LootData.Rare} tooltipLabel="Rare loot" />}
                    {props.personadata.LootData.Epic > 0 && <GameCardLootToken lootIcon={<EpicTreasureIcon h="6" />} count={props.personadata.LootData.Epic} tooltipLabel="Epic loot" />}
                    {props.personadata.LootData.Legendary > 0 && <GameCardLootToken lootIcon={<LegendaryTreasureIcon h="6" />} count={props.personadata.LootData.Legendary} tooltipLabel="Legendary loot" />}
                </CardBody>
            </Card>}
        </>
    )
}
