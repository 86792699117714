import {
    Text,
    Heading,
    Flex,
} from "@chakra-ui/react"

export const PersonaPanelHeading = ({ ...props }) => {
    return (
        <Flex direction="column" padding="6">
            <Heading size="lg">
                {props.personaName}
            </Heading>
            <Text fontSize="s" paddingLeft="4">{props.personaDescription}</Text>
        </Flex>
    )
}
