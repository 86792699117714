import * as React from "react"
import { BasePersona } from "../../BasePersona";
import { GameManager } from "../../GameManager";
import { GameCard } from "../Card/GameCard";
import { CardsPanel } from "./CardsPanel";

export const EnemyCardsPanel = ({ ...props }) => {
  const personas = GameManager.Instance.currentEnemies;
  const updatePersonaCards = (cards: BasePersona[]) => {
    return personas.map(persona => (
      <GameCard key={persona.Name} persona={persona} onPanelOpen={props.onPanelOpen}/>
    ));
  };
  const [personaCards, setPersonaCards] = React.useState(updatePersonaCards(personas));

  GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
    setPersonaCards(updatePersonaCards(GameManager.Instance.currentEnemies));
  });

  return (
    <CardsPanel personaCards={personaCards} />
  )
}
