import basePack from './packs/base.json';
import heavenfallPack from './packs/heavenfall.json';
import rainbowCrossingPack from './packs/rainbow_crossing.json';
import { IGamePack } from './IGamePack';

export default class GameData {
    static packs: IGamePack[];
    static hasInit = false;
    public static GetAllPacks(): IGamePack[] {
        this.Init();
        return this.packs;
    }

    protected static Init() {
        if (this.hasInit) {
            return;
        }
        this.hasInit = true;
        if (this.packs == null) {
            this.packs = [basePack, heavenfallPack, rainbowCrossingPack];
        }
        this.packs.forEach(pack => {
           pack.playerCards.forEach(player => {
                player.avaliable = true;
           });
           pack.mobCards.forEach(enemy => {
                enemy.avaliable = true;
           });
           pack.roamingMonsterCards.forEach(enemy => {
                enemy.avaliable = true;
           });
           pack.bossCards.forEach(enemy => {
                enemy.avaliable = true;
           });
        });
    }
}
