import * as React from "react"
import {
    Button,
    Divider,
    AbsoluteCenter,
    Box,
    HStack,
    Text,
    Spacer,
    useDisclosure,
    Tooltip,
} from "@chakra-ui/react"
import { CommonTreasureIconButton, RareTreasureIconButton, EpicTreasureIconButton, LegendaryTreasureIconButton, CommonTreasureIcon, RareTreasureIcon, EpicTreasureIcon, LegendaryTreasureIcon } from "../TreasureIcons"
import { LootDrawnModal } from "./LootDrawnModal"
import { ILootData } from "../../ILootData"
import { GameManager } from "../../GameManager"
import { LootDrawnToken } from "../LootDrawnToken"

export const TreasureSettings = ({ ...props }) => {
    const emptyLootData: ILootData = {
        Common: 0,
        Rare: 0,
        Epic: 0,
        Legendary: 0
    }
    const [lootData, setLootData] = React.useState(emptyLootData);
    const [modalTitle, setModalTitle] = React.useState("Loot Drawn");
    const modalDisclosure = useDisclosure();

    const initialGameManagerData = GameManager.Instance.GetData();
    const getHasBorrowed = (loot: ILootData) => {
        return (loot.BorrowedCommon ?? 0) + (loot.BorrowedRare ?? 0) + (loot.BorrowedEpic ?? 0) + (loot.BorrowedLegendary ?? 0) > 0;
    }
    const [hasBorrowedLoot, setHasBorrowedLoot] = React.useState(getHasBorrowed(initialGameManagerData.treasureBag));
    const [borrowedLoot, setBorrowedLoot] = React.useState(initialGameManagerData.treasureBag);
    const [remainingLoot, setRemainingLoot] = React.useState(initialGameManagerData.remainingTreasure);

    GameManager.Instance.AttachUIUpdater(React.useId(), gameManagerData => {
        const loot = gameManagerData.treasureBag;
        setBorrowedLoot(loot);
        setRemainingLoot(gameManagerData.remainingTreasure);
        setHasBorrowedLoot(getHasBorrowed(loot));
    });

    return (
        <>
            <Box position='relative' padding='4'>
                <Divider />
                <AbsoluteCenter px='4'>
                    Treasure
                </AbsoluteCenter>
            </Box>
            <HStack alignItems="stretch">
                <Tooltip label="Temporarily take loot from the treasure bag" placement="auto">
                    <Button flex="1" onClick={() => { setLootData(GameManager.Instance.BorrowLoot(1)); setModalTitle("Borrowed Loot Drawn"); modalDisclosure.onOpen(); }}>
                        Borrow Loot
                    </Button>
                </Tooltip>
                <Tooltip label="Look at a drawn loot token and return it" placement="auto">
                    <Button flex="1" onClick={() => { setLootData(GameManager.Instance.PeekLoot(1)); setModalTitle("Peeked at Loot"); modalDisclosure.onOpen(); }}>
                        Peek at Loot
                    </Button>
                </Tooltip>
            </HStack>
           <HStack>
                <Text>Borrowed Loot</Text>
                <Spacer />
                {!hasBorrowedLoot && <Text>N/A</Text>}
                {((borrowedLoot.BorrowedCommon ?? 0) > 0) && <LootDrawnToken lootIcon={<CommonTreasureIcon h="6" />} count={borrowedLoot.BorrowedCommon} tooltipLabel="Common Loot" fontsize="md" paddingleft="0.5" hidden={((borrowedLoot.BorrowedCommon ?? 0) > 0)} />}
                {((borrowedLoot.BorrowedRare ?? 0) > 0) && <LootDrawnToken lootIcon={<RareTreasureIcon h="6" />} count={borrowedLoot.BorrowedRare} tooltipLabel="Rare Loot" fontsize="md" paddingleft="0.5" hidden={((borrowedLoot.BorrowedRare ?? 0) > 0)} />}
                {((borrowedLoot.BorrowedEpic ?? 0) > 0) && <LootDrawnToken lootIcon={<EpicTreasureIcon h="6" />} count={borrowedLoot.BorrowedEpic} tooltipLabel="Epic Loot" fontsize="md" paddingleft="0.5" hidden={((borrowedLoot.BorrowedEpic ?? 0) > 0)} />}
                {((borrowedLoot.BorrowedLegendary ?? 0) > 0) && <LootDrawnToken lootIcon={<LegendaryTreasureIcon h="6" />} count={borrowedLoot.BorrowedLegendary} tooltipLabel="Legendary Loot" fontsize="md" paddingleft="0.5" hidden={((borrowedLoot.BorrowedLegendary ?? 0) > 0)} />}
                <LegendaryTreasureIconButton hidden={true} />
            </HStack>
            <HStack>
                <Text>Return to Bag</Text>
                <Spacer />
                <CommonTreasureIconButton isDisabled={(borrowedLoot.BorrowedCommon ?? 0) <= 0} onClick={() => { GameManager.Instance.ReturnLootToBag(1, 0, 0, 0) }} />
                <RareTreasureIconButton isDisabled={(borrowedLoot.BorrowedRare ?? 0) <= 0} onClick={() => { GameManager.Instance.ReturnLootToBag(0, 1, 0, 0) }} />
                <EpicTreasureIconButton isDisabled={(borrowedLoot.BorrowedEpic ?? 0) <= 0} onClick={() => { GameManager.Instance.ReturnLootToBag(0, 0, 1, 0) }} />
                <LegendaryTreasureIconButton isDisabled={(borrowedLoot.BorrowedLegendary ?? 0) <= 0} hidden={true} onClick={() => { GameManager.Instance.ReturnLootToBag(0, 0, 0, 1) }} />
            </HStack>
            <HStack>
                <Text>Add to Bag</Text>
                <Spacer />
                <CommonTreasureIconButton isDisabled={(remainingLoot.Common ?? 0) <= 0} onClick={() => { GameManager.Instance.AddTreasureToBag(1, 0, 0, 0) }} />
                <RareTreasureIconButton isDisabled={(remainingLoot.Rare ?? 0) <= 0} onClick={() => { GameManager.Instance.AddTreasureToBag(0, 1, 0, 0) }} />
                <EpicTreasureIconButton isDisabled={(remainingLoot.Epic ?? 0) <= 0} onClick={() => { GameManager.Instance.AddTreasureToBag(0, 0, 1, 0) }} />
                <LegendaryTreasureIconButton hidden={true} isDisabled={(remainingLoot.Legendary ?? 0) <= 0} onClick={() => { GameManager.Instance.AddTreasureToBag(0, 0, 0, 1) }} />
            </HStack>
            <LootDrawnModal lootData={lootData} isopen={modalDisclosure.isOpen} onclose={modalDisclosure.onClose} title={modalTitle} />
        </>
    )
}
