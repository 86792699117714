import { BasePersona } from "./BasePersona";
import { EnemyType } from "./EnemyType";
import { GameManager } from "./GameManager";
import { IBossCard } from "./IBossCard";
import { IDamageModalData } from "./IDamageModalData";
import { IEnemyCard } from "./IEnemyCard";
import { IEnemyCardBase } from "./IEnemyCardBase";
import { IEnemyCardData } from "./IEnemyCardData";

export class Enemy extends BasePersona {
    public get Description(): string {
        return "Level " + this.GetLevelName(this.Level);
    }
    private _minionsLeft: number = 0;
    public override get MinionsLeft(): number {
        return this._minionsLeft;
    }
    protected set MinionsLeft(value: number) {
        this._minionsLeft = value;
    }
    public override get ShowMinionsLeft(): boolean {
        return this._enemyCard.enemyType === EnemyType.Mob;
    }
    public override get MaxHealth(): number {
        return this.GetMaxHealth();
    }
    private _players: number;

    private _enemyCard: IEnemyCardBase;

    public constructor(card: IEnemyCardBase, players: number, level: number) {
        super(card);
        this._enemyCard = card;
        this.Level = level;
        this._players = players;
        this.Health = this.MaxHealth;
        if (card.enemyType === EnemyType.Mob) {
            this.MinionsLeft = players;
        }
        this.NeedsDefaultLoot = true;
    }

    public GetMaxHealth(): number {
        let ret = this.GetBaseHealthForLevel(this.Level);
        if (this._enemyCard.enemyType !== EnemyType.Mob) {
            ret *= this._players;
        }
        return ret;
    }

    protected GetLevelCard(level: number): IEnemyCardData {
        if (this._enemyCard.enemyType === EnemyType.Boss) {
            const bossCard = this._enemyCard as IBossCard;
            return bossCard.oneShotCard;
        }
        else {
            const enemyCard = this._enemyCard as IEnemyCard;
            switch (level) {
                case 1:
                case 2:
                    return enemyCard.level1To2Card;
                case 3:
                case 4:
                    return enemyCard.level3To4Card;
                case 5:
                    return enemyCard.level5Card;
                case 6:
                case 7:
                    return enemyCard.level6To7Card;
                case 8:
                case 9:
                    return enemyCard.level8To9Card;
                case 10:
                    return enemyCard.level10Card;
            }
        }
        return {
            baseHealth: 1,
            loot: {
                Bag: 0,
                Common: 0,
                Rare: 0,
                Epic: 0,
                Legendary: 0
            }
        }
    }

    public GetBaseHealthForLevel(level: number): number {
        const data = this.GetLevelCard(level);
        return data.baseHealth;
    }

    public GetNextLevel(level: number): number {
        switch (level) {
            case 1:
            case 2:
                return 4;
            case 3:
            case 4:
                return 5;
            case 5:
                return 7;
            case 6:
            case 7:
                return 8;
            case 8:
            case 9:
            case 10:
                return 10;
        }
        return 1;
    }

    public GetLevelName(level: number): string {
        switch (level) {
            case 1:
            case 2:
                return "1-2";
            case 3:
            case 4:
                return "3-4";
            case 5:
                return "5";
            case 6:
            case 7:
                return "6-7";
            case 8:
            case 9:
                return "8-9";
            case 10:
                return "10";
        }
        return this.Level.toString();
    }

    public override AddDefaultLoot() {
        this.NeedsDefaultLoot = false;
        const data = this.GetLevelCard(this.Level);
        const bagNum = data.loot.Bag ?? 0;
        GameManager.Instance.AttachRandomLootToPersona(this, bagNum);
        GameManager.Instance.AttachLootToPersona(this, data.loot);
    }

    public override LevelUp() {
        if (this.Level === GameManager.Instance.MaxLevel) {
            return;
        }
        this.UpdateLevel(this.GetNextLevel(this.Level));
    }

    public UpdateLevel(newLevel: number) {
        if (newLevel > GameManager.Instance.MaxLevel) {
            newLevel = GameManager.Instance.MaxLevel;
        }
        const oldMaxHealth = this.GetMaxHealth();
        this.Level = newLevel;
        const newMaxHealth = this.GetMaxHealth();
        this.Health += (newMaxHealth - oldMaxHealth);
        this.UpdateUI();
    }

    protected GetXPForKill(hasDied: boolean, minionsKilled: number, isAttacker: boolean): number {
        let xp = isAttacker ? minionsKilled : 0;
        if (hasDied) {
            if (isAttacker) {
                xp += 1;
            }
            switch (this._enemyCard.enemyType) {
                case EnemyType.Mob:
                    xp += 2;
                    break;
                case EnemyType.RoamingMonster:
                    xp += 4;
                    break;
            }
        }
        return xp;
    }

    public override Damage(damage: number): IDamageModalData | null {
        this.Health -= damage;
        let minionsKilled = 0;
        let hasDied = false;
        while (this.Health <= 0) {
            if (this.MinionsLeft === 0) {
                this.Health = 0;
                hasDied = true;
                break;
            }

            --this.MinionsLeft;
            ++minionsKilled;
            this.Health += this.MaxHealth;
        }
        this.UpdateUI();
        if (minionsKilled > 0 || hasDied) {
            if (this._enemyCard.enemyType === EnemyType.Boss) {
                return {
                    title: "Boss Killed",
                    text: "Quest Completed!",
                    loot: null,
                }
            }
            const title = hasDied ? "Enemy Killed" : (minionsKilled > 1 ? "Minions Killed" : "Minion Killed");
            const attackerXp = this.GetXPForKill(hasDied, minionsKilled, true);
            const teamXp = this.GetXPForKill(hasDied, minionsKilled, false);
            let text = `Give ${attackerXp} XP to the player that attacked`;
            if (teamXp > 0) {
                text += `\nGive ${this.GetXPForKill(hasDied, minionsKilled, false)} XP to all other players`;
            }
            const numLoot = this.LootData.Common + this.LootData.Rare + this.LootData.Epic + this.LootData.Legendary;
            if (hasDied && numLoot > 0) {
                text += "\n\nThe player that attacked receives the following loot:";
            }
            return {
                title: title,
                text: text,
                loot: hasDied ? this.LootData : null,
            }
        }
        return null;
    }

    protected OnMinionDeath() {

    }

    protected OnDeath() {

    }
}
