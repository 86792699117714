import React from "react"
import {
    Accordion,
} from "@chakra-ui/react"
import GameData from "../GameData";
import { PersonaPackAccordion } from "./PersonaPackAccordion";


export const PersonaPicker = ({ ...props }) => {
    const accordionItems: React.ReactNode[] = [];
    const packs = GameData.GetAllPacks();
    for (let index = 0; index < packs.length; index++) {
        const pack = packs[index];
        accordionItems.push(<PersonaPackAccordion pack={pack} key={pack.name} showplayers={props.showplayers} enemytype={props.enemytype} getcheckboxprops={props.getcheckboxprops} />)
    }

    return (
        <>
            <Accordion defaultIndex={[0]} allowMultiple>
                {accordionItems}
            </Accordion>
        </>
    );
}
