import * as React from "react"
import {
  HStack,
  VStack,
  Text,
  Spacer,
} from "@chakra-ui/react"

export const CardsPanel = ({ ...props }) => {

  return (
    <>
    {props.personaCards.length === 0 &&
      <VStack alignItems="stretch" flex="1" >
        <Spacer />
        <Text flex="0" textAlign="center">No cards added</Text>
        <Text flex="0" textAlign="center">Add them using the settings icon at top right</Text>
        <Spacer />
      </VStack>
    }
    {props.personaCards.length > 0 &&
      <HStack spacing="4" backgroundColor={props.backgroundColor} overflowX="auto" overflowY="hidden" alignItems="stretch" flex="1" padding="2">
        {props.personaCards}
      </HStack>}
    </>
  )
}
